// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getAuth } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDkgBHK4JP_B-smc9sNhfa9DTTKma4nN5Y",
  authDomain: "barkstrology-axl.firebaseapp.com",
  projectId: "barkstrology-axl",
  storageBucket: "barkstrology-axl.appspot.com",
  messagingSenderId: "964248215933",
  appId: "1:964248215933:web:48b30049c3a58e510f6c14",
  measurementId: "G-P9LL5W6DCF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firebaseui = require('firebaseui');
const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app);
const analytics = getAnalytics(app);

// Conditionally connect to emulators for local development
if (window.location.hostname === "localhost") {
    connectFirestoreEmulator(db, "localhost", 8081); // Use the correct port for your Firestore emulator
    connectFunctionsEmulator(functions, "localhost", 5001); // Use the correct port for your Functions emulator
}

export { auth, db, functions, firebaseui, analytics };
